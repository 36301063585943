<template>
    <div class="px-6 mt-10">
        <ContentLoader
            width="595"
            height="329"
            :speed="2"
            primaryColor="#f7fafc"
            secondaryColor="#edf2f7">
            <rect width="83" height="12" rx="5" />
            <path d="M0 27H587C591.418 27 595 30.5817 595 35V160C595 164.418 591.418 168 587 168H0V27Z" />
            <rect x="53" y="234" width="492" height="25" />
            <rect x="53" y="269" width="492" height="25" />
            <rect x="53" y="304" width="492" height="25" />
            <rect x="229" y="191" width="66" height="20" rx="5" />
            <rect x="303" y="191" width="66" height="20" rx="5" />
        </ContentLoader>
    </div>
</template>

<script>
import {
    ContentLoader,
} from 'vue-content-loader';

export default {
    name: 'TutorialLoader',

    components: {
        ContentLoader,
    },
};
</script>
