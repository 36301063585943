<template>
    <div class="flex">
        <div class="w-full md:w-11/12 lg:w-full xl:w-11/12 mx-auto">
            <template v-if="isLoadingTutorial">
                <TutorialLoader />
            </template>
            <template v-else>
                <div class="mt-10 mb-8 px-6 flex">
                    <router-link to="/tutorials" class="text-orange-500 transition duration-200 ease-in-out hover:text-orange-600">Browse All Tutorials</router-link>
                </div>
                <div class="px-6">
                    <!-- Tutorial Banner -->
                    <div class="tutorial-banner opacity-0 bg-white shadow-md border border-gray-200 py-4 rounded-tr-lg rounded-br-lg relative flex flex-col md:flex-row">
                        <div class="absolute left-0 top-0 h-full w-1 bg-orange-gradient"></div>
                        <div class="flex flex-row justify-center items-center relative py-2 w-full h-32 md:w-1/3 md:h-56 md:py-4">
                            <img
                                :src="tutorial.artwork"
                                class="absolute h-full"
                            />
                        </div>
                        <div class="w-full md:w-2/3 h-full pt-4 pb-8 px-6">
                            <h2 class="text-xl md:text-3xl block mb-3 font-semibold text-gray-900">{{ tutorial.title }}</h2>
                            <p class="text-base mb-6 text-gray-700">{{ tutorial.description }}</p>
                            <div class="w-full flex flex-col-reverse md:flex-row items-start md:items-center md:justify-between">
                                <ProfileId :profile="tutorial.author" class="text-gray-700" />
                                <div class="flex flex-row text-sm text-gray-700 mb-6 md:mb-0">
                                    <span class="mr-3">{{ `${lessons.length} Lesson${lessons.length > 1 ? 's' : ''}` }}</span>
                                    <span class="text-sm flex flex-row items-center">
                                        <timeSvg class="h-4 mr-1 fill-current" />{{ estimatedCompletionTime }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <rewardSvg
                            v-if="completed"
                            class="completion-badge absolute h-16 md:h-20 -bottom-8 md:-bottom-12 right-0"
                        />
                    </div>

                    <!-- Tutorial Start Controls -->
                    <div
                        v-if="!isMobile"
                        class="hidden sm:flex flex-col sm:flex-row items-center justify-center w-12/12 sm:w-10/12 ml-auto mr-auto mt-12">
                        <TutorialRuntime
                            class="relative w-full sm:w-32 mb-3 sm:mb-0 z-10"
                            :runtimes="tutorial.runtimes"
                            :projectRuntime="projectRuntime"
                            :locked="tutorial.userData !== undefined"
                            v-on:change-runtime="onChangeRuntime"
                        />
                        <button
                            v-if="!completed"
                            @click="() => !lessonStarting && handleOnStart()"
                            class="transition duration-200 bg-green-500 hover:bg-green-600 text-white ease-in-out w-full sm:w-auto mb-3 sm:mb-0 sm:ml-3 py-2 px-4 rounded shadow-sm"
                            :class="{
                                'opacity-50 cursor-wait': lessonStarting,
                            }">
                            {{ tutorialStartPrompt }}
                        </button>
                        <button
                            v-if="attempted"
                            @click="() => !tutorialResetting && doTutorialReset()"
                            class="transition duration-200 bg-red-500 hover:bg-red-600 ease-in-out w-full sm:w-auto mb-3 sm:mb-0 sm:ml-3 text-white py-2 px-4 rounded shadow-sm"
                            :class="{
                                'opacity-50 cursor-wait': tutorialResetting,
                            }">
                            {{ tutorialResetting ? 'Resetting...' : 'Reset' }}
                        </button>
                    </div>

                    <!-- Mobile Disabled banner -->
                    <div
                        v-if="isMobile"
                        class="block sm:hidden mt-12 bg-blue-100 px-3 py-4 border border-blue-500 rounded">
                        <p class="text-blue-600">
                            Interactive Tutorials are currently not supported on mobile devices. To ensure an enhanced experience, kindly access this tutorial from a laptop/desktop browser instead.
                        </p>
                    </div>

                    <!-- Tutorial Lessons -->
                    <div class="w-12/12 md:w-10/12 ml-auto mr-auto mt-10 mb-32">
                        <div
                            v-if="completed"
                            class="block my-6 bg-green-100 px-6 py-4 border border-green-500 rounded">
                            <p class="text-green-600">
                                You've completed this tutorial in {{ this.projectRuntime }}, nice work! 🥳 Go ahead and <router-link to="/tutorials" class="font-bold">browse all tutorials</router-link> to find new topics to explore.
                            </p>
                        </div>

                        <LessonItem
                            class="stagger-lesson-item"
                            v-for="(lesson, idx) in lessons"
                            :key="idx"
                            :tutorialSlug="tutorialSlug"
                            :lesson="lesson"
                            :locked="!sessionActive || !courseActive"
                            v-on:go-to-lesson="goToLesson"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
/* global umami */
/* eslint-disable no-underscore-dangle */

import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
} from 'vuex';

import { EventBus } from '@/utils';

import TutorialLoader from '@/components/tutorials/TutorialLoader.vue';

import mixin from '@/components/tutorials/mixin';

import {
    initializeSession,
    setupSession,
    endSession,
} from '@/utils/api/socket/session';

import { getSessionId } from '@/utils/auth';

export default {
    name: 'Tutorial',

    mixins: [mixin],

    props: {
        tutorialSlug: {
            type: String,
            required: true,
        },
    },

    created() {
        this.loadTutorial();
    },

    mounted() {
        this.playTutorialBanner();
        this.playStaggerLessonItems();
    },

    updated() {
        this.playTutorialBanner();
        this.playStaggerLessonItems();
    },

    data() {
        return {
            lessonItemsStaggered: false,
            startTutorialAfterSignIn: false,

            lessonStarting: false,
            tutorialResetting: false,
        };
    },

    computed: {
        ...mapState([
            'isMobile',
        ]),

        ...mapState('auth', [
            'sessionActive',
        ]),

        ...mapState('project', [
            'projectRuntime',
        ]),

        ...mapState('tutorials', [
            'tutorial',
            'isLoadingTutorial',
        ]),

        ...mapGetters('tutorials', [
            'lessons',
        ]),

        defaultProjectRuntime() {
            return this.tutorial.runtimes[0].name;
        },

        attempted() {
            return this.tutorial.userData !== undefined;
        },

        completed() {
            const totalLessons = this.lessons.length;
            const { userData } = this.tutorial;
            if (userData === undefined) return false;
            return userData.stat.completed.length === totalLessons;
        },

        courseActive() {
            return this.tutorial.userData !== undefined;
        },

        nextLesson() {
            const earliestNotCompleted = this.lessons
                .find((l) => l.completed !== true);
            return earliestNotCompleted;
        },

        estimatedCompletionTime() {
            const courseWords = this.lessons.reduce((base, lesson) => {
                const lessonWords = lesson.content[0].content;
                return `${base} ${lessonWords}`;
            }, '');

            const estimate = this.getEstimatedCompletionTime(courseWords);
            return estimate;
        },

        tutorialStartPrompt() {
            let prompt;

            if (this.sessionActive) {
                if (!this.attempted) {
                    prompt = this.lessonStarting ? 'Starting...' : 'Start';
                }

                if (this.attempted && !this.completed) {
                    prompt = this.lessonStarting ? 'Resuming...' : 'Resume';
                }
            } else {
                prompt = 'Sign In & Start';
            }

            return prompt;
        },
    },

    watch: {
        tutorialSlug() {
            this.loadTutorial();
        },

        sessionActive() {
            if (this.sessionActive && this.startTutorialAfterSignIn) {
                this
                    .getTutorial({ tutorialSlug: this.tutorialSlug })
                    .then(this.handleOnStart);
            } else if (this.sessionActive) {
                this.getTutorial({ tutorialSlug: this.tutorialSlug });
            } else {
                // endSession();
                this.getTutorial({ tutorialSlug: this.tutorialSlug });
            }
        },

        tutorial() {
            let runtime;
            if (this.tutorial.userData) {
                runtime = this.tutorial.userData.runtime;
            } else if (this.projectRuntime) {
                runtime = this.projectRuntime;
            } else {
                runtime = this.defaultProjectRuntime;
            }
            this.setProjectRuntime(runtime);
        },
    },

    methods: {
        ...mapMutations('tutorials', [
            'setTutorial',
            'setLesson',
        ]),

        ...mapActions('tutorials', [
            'getTutorial',
            'updateTutorialStats',
            'deleteTutorialStats',
        ]),

        ...mapMutations('project', [
            'setProjectHost',
            'setProjectApp',
            'setProjectRuntime',
        ]),

        loadTutorial() {
            this.lessonItemsStaggered = false;
            if (this.tutorial.slug !== this.tutorialSlug) {
                this.getTutorial({ tutorialSlug: this.tutorialSlug });
            }
        },

        onChangeRuntime(runtime) {
            this.setProjectRuntime(runtime);
        },

        handleOnStart() {
            if (!this.attempted && !this.completed) {
                this.doTutorialStart();
            }

            if (this.attempted && !this.completed) {
                this.goToLesson(this.nextLesson);
            }
        },

        doTutorialStart() {
            if (this.sessionActive) {
                this.doLessonStart();
            } else {
                this.startTutorialAfterSignIn = true;
                EventBus.$emit('doSignIn');
            }
        },

        async doTutorialReset() {
            try {
                this.tutorialResetting = true;
                await this.deleteTutorialStats({
                    tutorialId: this.tutorial._id,
                });
                this.setProjectRuntime(this.defaultProjectRuntime);
                this.tutorialResetting = false;
            } catch (ex) {
                console.error(ex);
                this.tutorialResetting = false;
            }
        },

        async startLessonSession({ runtime }) {
            const sessionId = getSessionId();
            if (sessionId !== undefined) return;
            const { app } = await initializeSession();
            const sessionSetup = await setupSession({
                runtime,
                projectTemplate: {
                    id: this.tutorial._id,
                    type: 'yoda',
                },
            });
            const projectHost = `https://${sessionSetup.host}`;
            this.setProjectHost(projectHost);
            this.setProjectApp(app);
        },

        async doLessonStart() {
            try {
                const runtime = this.projectRuntime;

                const firstLesson = this.tutorial.lessons
                    .find((l) => l.runtime === runtime);

                const updateData = {
                    runtime,
                    stat: {
                        lastAttempted: {
                            lessonId: firstLesson._id,
                        },
                        completed: [],
                    },
                };

                this.lessonStarting = true;
                await this.startLessonSession({
                    runtime,
                });
                await this.updateTutorialStats({
                    tutorialId: this.tutorial._id,
                    data: updateData,
                });
                this.goToLesson(firstLesson);

                umami.track(`tutorial-open-products-${this.tutorial.products.join('_')}`, 'sandbox');
                umami.track(`tutorial-runtime-${runtime}`, 'sandbox');
            } catch (ex) {
                console.error(ex);
                this.lessonStarting = false;
            }
        },

        async goToLesson(lesson) {
            try {
                this.lessonStarting = true;
                await this.startLessonSession({
                    runtime: this.tutorial.userData.runtime,
                });
                this.setLesson(lesson);

                this.$router.push({
                    name: 'lesson',
                    params: {
                        tutorialSlug: this.tutorial.slug,
                        lessonId: lesson._id,
                    },
                });
            } catch (ex) {
                console.error(ex);
                this.lessonStarting = false;
            }
        },
    },

    beforeRouteLeave(to, from, next) {
        if (to.name !== 'lesson') {
            this.setTutorial({});
            this.setProjectRuntime(null);
            const sessionId = getSessionId();
            if (sessionId) {
                endSession();
            }
        }

        next();
    },

    components: {
        TutorialLoader,
        TutorialRuntime: () => import('./TutorialRuntime.vue'),
        rewardSvg: () => import('@/assets/img/reward.svg'),
        timeSvg: () => import('@/assets/img/time.svg'),
        ProfileId: () => import('@/components/ui/ProfileId.vue'),
        LessonItem: () => import('@/components/tutorials/lessons/LessonItem.vue'),
    },
};
</script>
